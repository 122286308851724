<template>
  <a-spin :spinning="loading" tip="加载中">
    <div class="wpsFile" style="min-height: 600px"></div>
  </a-spin>
</template>
<script>
import WebOfficeSDK from "@/assets/wps/web-office-sdk-solution-v1.1.27.es";
import request from "@/request/index";
import commons from "@/utils/commons";
import config from "@/config";
import documentreadApi from "@/api/work/documentread";
import documentdispatchApi from "@/api/work/documentdispatch";
import { Base64 } from "js-base64";

export default {
  name: "wpsRead",
  mounted() {
    this.loading = false;
    let token = localStorage.getItem("Authenticationdls");
    if (token) {
      this.wenyin = JSON.parse(Base64.decode(token.split(".")[1]))["RoleClaim"].indexOf("文印员") != -1
    }
    this.qrcodeData = this.$route.query;
    this.readOnly = this.qrcodeData.readOnly
      ? this.qrcodeData.readOnly
      : "true";
    if (this.qrcodeData.as) {
      if (localStorage.getItem("hyData")) {
        this.hyData = JSON.parse(localStorage.getItem("hyData"));
      }
      request
        .post(
          config.baseUrl +
          "/share/" +
          this.part_url +
          localStorage.getItem("fileId") +
          "/" +
          localStorage.getItem(commons.User.userName),
          {},
          this.hyData
        )
        .then((res) => {
          this.fileId = res.data.id;
          this.url = res.data.url;
          this.name = res.data.name;
          this.officeType = "doc";
          this.fileSuffix = this.url.substring(this.url.lastIndexOf("."));
          this.init();
        });
    } else {
      this.fileType = "attachment";
      this.fileId = localStorage.getItem("fileId");
      this.url = localStorage.getItem("fileRrl");
      this.name = localStorage.getItem("fileName");
      this.officeType = localStorage.getItem("fileTpye");
      this.fileSuffix = this.url.substring(this.url.lastIndexOf("."));
      this.init();
    }
  },
  props: {
    file: {
      type: String,
    },
    templateFile: {
      type: String,
    },
  },
  data() {
    return {
      wps: null,
      loading: false,
      qrcodeData: null,
      part_url: "v3/custom/getUrl/",
      fileId: null,
      fileName: null,
      creatorId: null,
      id: null,
      url: null,
      name: null,
      officeType: null,
      readOnly: "true",
      fileType: "system",
      fileSuffix: "",
      hyData: {},
      wenyin: false,
    };
  },
  methods: {
    async init() {
      let _this = this;
      let customArgs = {
        userId: localStorage.getItem(commons.User.userName),
        reOnly: _this.readOnly,
        fileRrl: _this.url,
        fileName: _this.name,
        appKey: config.appKey,
      };
      let fileTpye = _this.officeType;
      let officeType;
      if (fileTpye.toLowerCase().indexOf("doc") != -1) officeType = "w";
      if (fileTpye.toLowerCase().indexOf("xls") != -1) officeType = "s";
      if (fileTpye.toLowerCase().indexOf("ppt") != -1) officeType = "p";
      if (fileTpye.toLowerCase().indexOf("pdf") != -1) officeType = "f";
      const instance = WebOfficeSDK.init({
        officeType: officeType,
        appId: config.wpsAppId,
        fileId: _this.fileId,
        token: "CCSJiXMpPIibHJxDaJPa",
        customArgs: customArgs,
        commonOptions: {
          isShowTopArea: true, // 隐藏顶部区域（头部和工具栏）
          isShowHeader: true, // 隐藏头部区域
        },
      });
      instance.ApiEvent.AddApiEventListener("fileOpen", (data) => {
        _this.fileName = data.fileInfo.name + _this.fileSuffix;
      });

      await instance.ready();
      const app = instance.Application;

      const controls = await app.CommandBars("StartTab").Controls;

      // 隐藏默认打印按钮
      const tabPrintPreview = await app.CommandBars("TabPrintPreview");
      tabPrintPreview.Visible = false;
      const menuPrintPreview = await app.CommandBars("MenuPrintPreview");
      menuPrintPreview.Visible = false;
      // 自定义打印
      const customPrintButton = await controls.Add(1);
      customPrintButton.Caption = "打印";
      customPrintButton.OnAction = async () => {
        // 来文阅办/发文办理打印前需要确认，其他直接打印
        if (
          _this.qrcodeData.printType &&
          (_this.qrcodeData.printType.indexOf("read") !== -1 ||
            _this.qrcodeData.printType.indexOf("fawen") !== -1) &&
          _this.hyData.print == 0 && _this.hyData.status == 2 && _this.wenyin
        ) {
          _this.$confirm({
            title: "打印状态将改为已打印，是否确认打印？",
            onOk() {
              tabPrintPreview.Execute();
              // 来文阅办/发文办理 自定义打印操作，记录文档已打印状态
              if (_this.qrcodeData.printType.indexOf("read") !== -1) {
                documentreadApi.updateOnly({
                  id: _this.hyData.id,
                  print: 1,
                });
              } else if (_this.qrcodeData.printType.indexOf("fawen") !== -1) {
                documentdispatchApi.updateOnly({
                  id: _this.hyData.id,
                  print: 1,
                });
              }
            },
            onCancel() { },
          });
        } else {
          await tabPrintPreview.Execute();
        }
      };
      // 添加自定义下载按钮
      function downloadFile(headerVal) {
        // 执行下载
        request
          .getUseHeader(
            config.baseUrl +
            "/share/v3/3rd/files/" +
            _this.fileId +
            "/download",
            {},
            {
              "X-User-Query": headerVal,
            }
          )
          .then((res) => {
            if (res.code == 0) {
              request.downloadFile(
                res.data.url,
                {},
                _this.fileName,
                () => { },
                () => { }
              );
            } else {
              _this.$message.error("下载失败，请稍后重试");
            }
          });
      }

      const customDownloadButton = await controls.Add(1); // 1 按钮
      customDownloadButton.Caption = "下载";
      customDownloadButton.OnAction = async () => {
        let headerVal = "fileId=" + _this.fileId;
        if (_this.fileType == "attachment") {
          headerVal += "&reOnly=true";
          headerVal += "&fileRrl=" + this.url;
        }
        let res = { result: "ok" };
        if (_this.fileType == "system") {
          res = await instance.save();
        }
        if (res.result == "ok" || res.result == "nochange") {
          // 来文阅办/发文办理打印前需要确认，其他直接打印
          if (
            _this.qrcodeData.printType &&
            (_this.qrcodeData.printType.indexOf("read") !== -1 ||
              _this.qrcodeData.printType.indexOf("fawen") !== -1) &&
            _this.hyData.print == 0 && _this.hyData.status == 2 && _this.wenyin
          ) {
            _this.$confirm({
              title: "打印状态将改为已打印，是否确认下载？",
              onOk() {
                downloadFile(headerVal);
                // 来文阅办/发文办理 自定义打印操作，记录文档已打印状态
                if (_this.qrcodeData.printType.indexOf("read") !== -1) {
                  documentreadApi.updateOnly({
                    id: _this.hyData.id,
                    print: 1,
                  });
                } else if (_this.qrcodeData.printType.indexOf("fawen") !== -1) {
                  documentdispatchApi.updateOnly({
                    id: _this.hyData.id,
                    print: 1,
                  });
                }
              },
              onCancel() { },
            });
          } else {
            downloadFile(headerVal);
          }
        } else {
          _this.$message.error("保存失败，请稍后重试");
        }
      };
    },
  },
};
</script>